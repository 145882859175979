// downloadStatus
module.exports.DOWNLOAD_STATUS = {
    QUEUING: "queuing",
    DOWNLOADING: "downloading",
    SUCCESS: "success",
    FAILED: "failed",
};
module.exports.UPLOAD_STATUS = {
    QUEUING: "queuing",
    UPLOADING: "uploading",
    SUCCESS: "success",
    FAILED: "failed",
};
