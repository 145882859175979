import { createRouter, createWebHashHistory } from 'vue-router'

import home from '../views/home/index.vue'
import transfer from '../views/transfer/index.vue'
const routes = [
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/transfer',
    name: 'transfer',
    // component: () => import(/* webpackChunkName: "about" */ '../views/transfer/index.vue')
    component:transfer
  },
  { 
    path: "/:catchAll(.*)", 
    redirect: "/home"
  },
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router