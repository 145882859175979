import { createApp } from 'vue';
import 'vant/lib/index.css';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import { Button, DropdownMenu, DropdownItem, Cell, Switch, Field } from 'vant'
import { RadioGroup, Radio } from 'vant';
import { CheckboxGroup, Checkbox } from 'vant';
import { Tab, Tabs } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { NavBar, Icon, Tag } from 'vant';
import { Dialog } from 'vant';
import { Uploader } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import { Image } from 'vant';
import { Pagination } from 'vant';
import { Loading } from 'vant';
import { Badge } from 'vant';
import { ConfigProvider } from 'vant';
import { createI18n } from 'vue-i18n'
import enLocale from './i18n/en';
import zhLocale from './i18n/zh';
// axios.defaults.baseURL="/proxy_api"
const messages = {
    zh: zhLocale,
    cn: zhLocale,
    en: enLocale,
    us: enLocale,

}
const localLang = navigator.language.split('-')[0];
const storageLang = window.localStorage.getItem('locale')?.split('"')[1].split('"')[0].toLocaleLowerCase() || 'en';
const c = (storageLang.toLocaleLowerCase() !== 'zh' && storageLang.toLocaleLowerCase() !== 'en') ? 'en' : storageLang;

const i18n = createI18n({
    globalInjection: true, //全局生效$t
    locale: localLang, //设置语言
    messages,
    legacy: false,
})
console.log("用户语言", localLang)

// import newIpData from "../public/static/myIP.js";
// console.log(newIpData);
// if (newIpData && newIpData.ip) {
//     const newIp = newIpData.ip;

//     // 在此处调用 setDeviceName 方法修改 deviceName
//     store.commit('setDeviceHost', `http://${newIp}:8888`);

//     console.log('deviceHost 已更新');
// } else {
//     console.log('无法读取新的 IP 地址');
// }



const app = createApp(App);
app.use(i18n)
app.use(Button);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Cell);
app.use(Switch);
app.use(Field);
app.use(RadioGroup).use(Radio);
app.use(CheckboxGroup).use(Checkbox);
app.use(Tab).use(Tabs);
app.use(Tabbar).use(TabbarItem);
app.use(NavBar).use(Icon).use(Tag);
app.use(Dialog);
app.use(Uploader);
app.use(Sidebar).use(SidebarItem);
app.use(Grid).use(GridItem);
app.use(Image);
app.use(Pagination);
app.use(Loading);
app.use(Badge);
app.use(ConfigProvider);

app.use(router).use(store).mount('#app')

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')



