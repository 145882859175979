<template>
    <div
        class="box_2 align-center flex-row justify-between"
        @click="openUploader"
    >
        <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/icon-upload.png"
        />

        <van-uploader
            :after-read="uploadFile"
            :preview-image="false"
            :multiple="false"
            accept="*"
            class="text_3"
            >{{ $t("tab1.sendtophone") }}</van-uploader
        >
    </div>
</template>
<script scoped>
import axios from "axios";
import { ref } from "vue";
import { mapGetters } from "vuex";
import { UPLOAD_STATUS } from "@/compositions/constants.js";
import HistoryComponent from "@/components/history/index.vue";

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
    },
    components: {
        HistoryComponent,
    },
    
    setup() {
        const fileList = ref([
            //          {
            //            url: 'https://fastly.jsdelivr.net/npm/@vant/assets/leaf.jpeg',
            //            status: 'uploading',
            //            message: 'Uploading...',
            //          },
            //          {
            //            url: 'https://fastly.jsdelivr.net/npm/@vant/assets/tree.jpeg',
            //            status: 'failed',
            //            message: 'Failed',
            //          },
        ]);
        
        // const afterRead = (file) => {
        //     this.uploadFile(file);
        // };
        const showHistoryDialog = ref(false);
        const closeHistoryDialog = () => {
            showHistoryDialog.value = false;
        };
        return {
            fileList,
            // afterRead,
            showHistoryDialog,
            closeHistoryDialog,
        };
    },
    methods: {
        async getVideoBase64(url) {
            const width = 400;
            const height = 300;

            return new Promise((resolve, reject) => {
                const video = document.createElement("video");
                video.width = width;
                video.height = height;

                const canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;

                video.src = url;

                video.onloadedmetadata = () => {
                    video.currentTime = 1;

                    video.onseeked = () => {
                        canvas
                            .getContext("2d")
                            .drawImage(
                                video,
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                        const img = canvas.toDataURL("image/jpeg");
                        resolve(img); // 返回缩略图的base64数据
                    };
                };

                video.onerror = (error) => {
                    reject(error); // 发生错误时，返回错误信息
                };
            });
        },
        uploadFile(file) {
            const fileName = encodeURIComponent(file.file.name);
            const fileEntry = {
                name: fileName, // 文件名
                status: UPLOAD_STATUS.UPLOADING, // 初始状态为上传中
                message: "Uploading...", // 初始消息
                fileType: fileName.split(".").pop().toLowerCase(),
                // staffPhoto: file.content, //待测试：回调函数显示图片
            };
            switch (fileEntry.fileType) {
                case "mp4":
                    fileEntry.fileTypeNumber = 4; // 视频类型为 4
                    this.getVideoBase64(file.content)
                        .then((response) => {
                            fileEntry.staffPhoto = response; // 将视频缩略图的 base64 赋值给 staffPhoto
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                    break;
                case "jpg":
                case "jpeg":
                case "png":
                    fileEntry.fileTypeNumber = 2; // 照片类型为 2
                    fileEntry.staffPhoto = file.content; // 待测试：回调函数显示图片
                    break;
                case "mp3":
                    fileEntry.fileTypeNumber = 3; // 音乐类型为 3
                    // 其他相关处理
                    break;
                case "ics":
                    fileEntry.fileTypeNumber = 5; // 日历类型为 5
                    // 其他相关处理
                    break;
                default:
                    fileEntry.fileTypeNumber = 0; // 默认未知类型为 0
                    break;
            }
            if (fileEntry.fileType === "mp4") {
                this.getVideoBase64(file.content)
                    .then((response) => {
                        fileEntry.staffPhoto = response; // 将视频缩略图的base64赋值给staffPhoto
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                // fileEntry.staffPhoto = this.getVideoBase64(file.content);
            } else {
                fileEntry.staffPhoto = file.content; //待测试：回调函数显示图片
            }

            this.fileList.push(fileEntry);

            const data = new FormData();
            data.append("file", file.file); //上传的是 File 对象

            const config = {
                //使用axios上传文件到服务器,注意设置axios的headers为{ "Content-Type": "multipart/form-data" }

                headers: {
                    "Content-Type": "multipart/form-data",
                    authorizationToken: this.getDeviceAuthToken,
                    fileType: fileEntry.fileTypeNumber,
                    name: "pc",
                },
            };
            axios
                .post(
                    this.getDeviceHost + "/upload",
                    data
                    //config
                )
                .then((response) => {
                    // 上传成功后，更新最后一项的文件状态和消息
                    const lastItemIndex = this.fileList.length - 1;
                    this.fileList[lastItemIndex].status = UPLOAD_STATUS.SUCCESS;
                    this.fileList[lastItemIndex].message = "Uploaded";
                    this.$emit("updateFileList", this.fileList); // 触发事件，传递最新的 fileList

                    // 上传成功后打开历史记录组件
                    this.$emit('openfileUploadedHistotySmall');  //上传后触发打开历史记录组件
                })
                .catch((error) => {
                    console.log(error);
                    // 更新最后一项的文件状态和消息为失败
                    const lastItemIndex = this.fileList.length - 1;
                    this.fileList[lastItemIndex].status = UPLOAD_STATUS.FAILED;
                    this.fileList[lastItemIndex].message = "Upload Failed";
                    this.$emit("updateFileList", this.fileList); // 触发事件，传递最新的 fileList
                });
        },
        close() {
            this.$emit("close");
        },
      
    },
};
</script>
<style scoped lang="css" src="./assets/index.css" />

<style scoped>
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

