<template>
    <div
        class="page flex-col justify-between"
        style="background-color: #f5f9fa"
    >
        <div class="box_1 flex-col">
            <div class="group_1 flex-row">
                <!-- 向子组件传递数据 -->
                <UploaderComponent
                    @click="openUploader"
                    @updateFileList="handleUpdateFileList"
                    @openfileUploadedHistotySmall="openHistoryDialog"
                />
                <div class="box_3 flex-row justify-between">
                    <img
                        class="label_2"
                        referrerpolicy="no-referrer"
                        src="./assets/img/icon-download.png"
                    />
                    <span
                        class="text_4"
                        style="cursor: pointer"
                        @click="donwloadSelectedFiles"
                        >{{ $t("tab1.savetopc") }}</span
                    >
                </div>
                <div class="box_4 flex-row">
                    <img
                        class="label_3"
                        referrerpolicy="no-referrer"
                        src="./assets/img/icon-history.png"
                    />
                    <span
                        class="text_5"
                        style="cursor: pointer"
                        @click="openHistoryDialogLarge"
                        >{{ $t("tab1.history") }}</span
                    >
                </div>
                <span class="text_6">{{ $t("tab1.connect") }}</span>
                <img
                    class="label_4"
                    referrerpolicy="no-referrer"
                    src="./assets/img/icon-connected.png"
                />
                <div class="text-wrapper_2 flex-col">
                    <span
                        class="text_7"
                        style="cursor: pointer"
                        @click="disconnect"
                        >{{ $t("tab1.disconnect") }}</span
                    >
                </div>
            </div>

            <div
                class="group_2 flex-row"
                style="
                    display: flex;
                    background-color: #fff;
                    border-radius: 10px;
                "
            >
                <van-sidebar
                    ref="transferTypeIcons"
                    v-model="transferTypeActive"
                    @change="onChangeTransferType"
                    style="
                        width: 10vw;
                        height: 65vh;
                        border-radius: 10px;
                        background-color: #fff;
                    "
                >
                    <!-- 侧边栏 -->
                    <van-sidebar-item>
                        <template #title>
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <img
                                    src="./assets/img/icon-photo.png"
                                    alt="icon"
                                    class="icon-image"
                                />
                                <span class="icon-text">{{
                                    $t("tab2.photo")
                                }}</span>
                            </div>
                        </template>
                    </van-sidebar-item>
                    <van-sidebar-item>
                        <template #title>
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <img
                                    src="./assets/img/icon-video.png"
                                    alt="icon"
                                    class="icon-image"
                                />
                                <span class="icon-text">{{
                                    $t("tab2.video")
                                }}</span>
                            </div>
                        </template>
                    </van-sidebar-item>
                    <van-sidebar-item>
                        <template #title>
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <img
                                    src="./assets/img/icon-music.png"
                                    alt="icon"
                                    class="icon-image"
                                />
                                <span class="icon-text">{{
                                    $t("tab2.music")
                                }}</span>
                            </div>
                        </template>
                    </van-sidebar-item>
                    <van-sidebar-item>
                        <template #title>
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <img
                                    src="./assets/img/icon-app.png"
                                    alt="icon"
                                    class="icon-image"
                                />
                                <span class="icon-text">{{
                                    $t("tab2.application")
                                }}</span>
                            </div>
                        </template>
                    </van-sidebar-item>
                    <van-sidebar-item>
                        <template #title>
                            <div
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                            >
                                <img
                                    src="./assets/img/icon-doc.png"
                                    alt="icon"
                                    class="icon-image"
                                />
                                <span class="icon-text">{{
                                    $t("tab2.document")
                                }}</span>
                            </div>
                        </template>
                    </van-sidebar-item>
                </van-sidebar>
                <div class="component_div" style="">
                    <!----TODO----->
                    <DownloadPhotoComponent
                        :fileList="fileList"
                        v-if="nevigator == 'photos'"
                        @openfileDownloadHistotySmall="
                            openDownloadHistoryDialog
                        "
                    />
                    <DownloadVideoComponent
                        :fileList="fileList"
                        v-if="nevigator == 'videos'"
                        @openfileDownloadHistotySmall="
                            openDownloadHistoryDialog
                        "
                    />
                    <DownloadMusicComponent
                        :fileList="fileList"
                        v-if="nevigator == 'musics'"
                        @openfileDownloadHistotySmall="
                            openDownloadHistoryDialog
                        "
                    />
                    <DownloadAppComponent
                        :fileList="fileList"
                        v-if="nevigator == 'apps'"
                        @openfileDownloadHistotySmall="
                            openDownloadHistoryDialog
                        "
                    />
                    <DownloadDocumentComponent
                        :fileList="fileList"
                        v-if="nevigator == 'docs'"
                        @openfileDownloadHistotySmall="
                            openDownloadHistoryDialog
                        "
                    />
                </div>
            </div>
        </div>
        <div class="box_8 flex-row">
            <div class="text-group_6 flex-col justify-between">
                <span class="text_14"
                    >
                    &#64;Copyright © Fullstack
                    Technology&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">(粤ICP备2021019301号-24)</a>. Powered by Fullstack
                    Technology</span
                >
                <!-- <span class="text_15">网站使用条款&nbsp;用户隐私协议</span> -->
            </div>
        </div>
        <!-- <UploadComponent :show="showUploadDialog" @close="closeUploadDialog" /> -->

        <HistoryComponent
            :fileList="fileList"
            :UploadFileList="UploadFileListP"
            :show="showHistoryDialog"
            :isSmall="showIsSmall"
            :activeTab="myActiveTab"
            @setActiveTabHistory="handleSetActiveTab('history')"
            @setActiveTabDownload="handleSetActiveTab('download')"
            @close="closeHistoryDialog"
            @changesize="changeDialogSize"
        />
        <!-- <van-dialog v-model="show" title="标题" show-cancel-button >
      <p>hello World</p>
    </van-dialog> -->
    </div>
</template>
<script>
import { ref } from "vue";
import UploaderComponent from "@/components/uploader/index.vue";
import HistoryComponent from "@/components/history/index.vue";
import DownloadPhotoComponent from "@/components/download/photo.vue";
import DownloadVideoComponent from "@/components/download/video.vue";
import DownloadMusicComponent from "@/components/download/music.vue";
import DownloadAppComponent from "@/components/download/application.vue";
import DownloadDocumentComponent from "@/components/download/document.vue";
import EventBus from "@/compositions/event-bus.js"; // 引入事件中心
import { mapGetters } from "vuex";
import axios from "axios";

export default {
    data() {
        return {
            timer: "",
            connectionStatus: "",
            show: false,
            nevigator: "photos",
            fileList: [],
            UploadFileListP: [],
            myActiveTab: "history",
        };
    },

    components: {
        UploaderComponent,
        HistoryComponent,
        DownloadPhotoComponent,
        DownloadVideoComponent,
        DownloadMusicComponent,
        DownloadAppComponent,
        DownloadDocumentComponent,
    },
    props: {
        connectionURL: {
            type: String,
            required: true,
        },
        // phoneUrl: {
        //   type: String,
        //   required: true
        // },
        // token: {
        //   type: String,
        //   required: true
        // }
    },
    computed: {
        // ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        selectedPhotos() {
            return this.$store.getters.getSelectedPhotos;
        },
    },
    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        checkTimer() {
            this.timer = setInterval(this.checkConnection, 2000);
        },
        checkConnection() {
            axios
                .get(this.getDeviceHost() + "/albums")
                .then((response) => {
                    // 处理服务器返回的连接状态数据
                    this.connectionStatus = response.data.code;
                    console.log("连接中：", this.connectionStatus);
                })
                .catch((error) => {
                    console.error("连接断开：", error);
                    this.connectionStatus = error.code;
                    console.log("this.connectionStatus", this.connectionStatus);
                    clearInterval(this.timer); // 停止定时器
                    window.alert("连接已断开");
                    this.disconnect();
                    
                });
        },
        disconnect() {
            // 断开连接的处理 修改：直接跳转官网
            // this.$router.push({ name: "home" });
            window.location.href = "http://www.eassync.com";
        },
        showHistory() {},
        setMaterialEditIcon() {
            // 按顺序设置材质替换各选项的图标
            let imgArr = [
                "icon-photo",
                "icon-video",
                "icon-music",
                "icon-app",
                "icon-doc",
            ];
            // 设置图标样式，可以不加
            const imgStyle =
                "height: 0.5rem; float: left; margin-right: 0.2rem; margin-top: -0.05rem;";
            imgArr.forEach((item, index) => {
                const html = `<img style='${imgStyle}' src='static/image/${item}.png' />`;
                // 使用innerHtml添加节点
                console.log(1111111);
                console.log(this.$refs.transferTypeIcons);
                // this.$refs.transferTypeIcons.$children[index].$el.innerHTML += html;
            });
        },
        onChangeTransferType(index) {
            let options = ["photos", "videos", "musics", "apps", "docs"];
            this.nevigator = options.at(index);
            // 清空 fileList   更正：fileList作为历史记录

            // this.fileList = [];
        },
        donwloadSelectedFiles() {
            EventBus.emit("download", {
                num: 1,
            });
            console.log("download is triggered.");
            // alert(1);
        },
        handleUpdateFileList(fileList) {
            // 这里你可以处理来自子组件的 fileList
            this.UploadFileListP = fileList;
            console.log("父组件的this.UploadFileListP", this.UploadFileListP);
        },
        handleSetActiveTab(tab) {
            this.myActiveTab = tab;
        },
    },
    setup() {
        const transferTypeActive = ref(0);

        const showUploadDialog = ref(false);
        const uploaderRef = ref(null);
        const openUploader = () => {
            showUploadDialog.value = true;
            console.log("showUploadDialog.value", showUploadDialog.value);
        };
        const openUploadDialog = () => {
            showUploadDialog.value = true;
        };

        const closeUploadDialog = () => {
            showUploadDialog.value = false;
        };

        const showHistoryDialog = ref(false);
        const showIsSmall = ref(true);
        const myActiveTab = ref("history");

        const openHistoryDialog = () => {
            console.log("历史记录小窗口被触发");
            showHistoryDialog.value = true;
            showIsSmall.value = true;
            myActiveTab.value = "history";
        };

        const openDownloadHistoryDialog = () => {
            console.log("下载记录小窗口被触发");
            showHistoryDialog.value = true;
            showIsSmall.value = true;
            myActiveTab.value = "download";
        };
        const openHistoryDialogLarge = () => {
            console.log("历史记录按钮触发");
            showHistoryDialog.value = true;
            showIsSmall.value = false;
        };

        const closeHistoryDialog = () => {
            showHistoryDialog.value = false;
        };
        const changeDialogSize = () => {
            // 改变历史记录大小
            showIsSmall.value = !showIsSmall.value;
        };
        return {
            showIsSmall,
            transferTypeActive,
            showUploadDialog,
            openUploadDialog,
            closeUploadDialog,
            showHistoryDialog,
            openHistoryDialog,
            openHistoryDialogLarge,
            closeHistoryDialog,
            uploaderRef,
            openUploader,
            changeDialogSize,
            openDownloadHistoryDialog,
            myActiveTab,
        };
    },
    mounted() {
        // 这里通过this.$nextTick确认组件已经加载完成
        this.$nextTick(() => {
            this.setMaterialEditIcon();
        });
        this.checkTimer();
    },
};
</script>
<style  scoped>
.van-sidebar-item--select::before {
    background-color: #60d197;
}
.van-sidebar-item {
    background-color: #fff;
    margin-top: 1vh;
}
.van-sidebar-item--select {
    background-color: #f6f9fa;
}

.icon-image {
    margin-left: 1vw;
    width: 30%;
    height: 30%;
}

.icon-text {
    margin-left: 1vw;
    width: 50%;
    height: 50%;
}
</style>
<style scoped lang="css" src="./assets/index.css" />