
<template>
    <div class="flex flex-col">
        <select-all
            :is-select-all="isSelectAll"
            @select-all="selectAll"
            @cancel-select-all="cancelSelectAll"
        />

        <div
            style="
                margin-left: 10px;
                width: 85vw;
                height: 60vh;
                border-radius: 10px;
                background-color: #ffffff;
                overflow-y: auto; /* 添加滚动条 */
            "
        >
            <!-- <ul>
                <li
                    v-for="(item, index) in documents"
                    :key="index"
                    class="document-item"
                    @click="selectDoc(item.path)"
                    :class="{ selected: selectedDocs.includes(item.path) }"
                >
                    <img
                        :src="getFileIcon(item.path)"
                        alt="icon"
                        class="file-icon"
                    />
                    <span class="file-name">{{
                        item.path.split("/").pop()
                    }}</span>
                    <van-icon
                        v-show="this.selectedDocs.includes(item.path)"
                        name="success"
                        class="badge-icon"
                    />
                </li>
            </ul> -->
            <ul>
    <li v-for="(item, index) in documents" :key="index" class="document-item" @click="selectDoc(item.path)" :class="{ selected: selectedDocs.includes(item.path) }">
        <img :src="getFileIcon(item.path)" alt="icon" class="file-icon" />
        <span class="file-name">{{ item.path.split("/").pop() }}</span>
        <img v-show="this.selectedDocs.includes(item.path)" src="./assets/img/icon-selected@2x.png" class="custom-icon" />
    </li>
</ul>

        </div>
    </div>
</template>

  <script scoped>
import axios from "axios";
import { mapGetters } from "vuex";
import SelectAll from "./selectAllTemplate.vue";
import { DOWNLOAD_STATUS } from "@/compositions/constants.js";
import EventBus from "@/compositions/event-bus.js";

export default {
    name: "DownloadDocumentComponent",
    data() {
        return {
            isSelectAll: false,
            documents: [],
            selectedDocs: [], //选择的列表
            // fileList: [], 
            childFileList: [], //下载的列表
            DOWNLOAD_STATUS,
            imgSrc: "",
        };
    },
    components: {
        SelectAll,
    },
    // props: {
    // connectionURL: {
    //   type: String,
    //   required: true
    // },
    // token: {
    //   type: String,
    //   required: true
    // }
    // },
    props: ["fileList"], // 接收来自父组件的 fileList

    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        selectDoc(path) {
            if (this.selectedDocs.includes(path)) {
                const index = this.selectedDocs.indexOf(path);
                this.selectedDocs.splice(index, 1);
                console.log("将选择的文件移除列表", this.selectedDocs);
            } else {
                this.selectedDocs.push(path);
                console.log("将选择的文件添加进列表中", this.selectedDocs);
            }
        },
        getAllDocuments() {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };
            axios
                .get(
                    this.getDeviceHost() + "/documents"
                    // config
                )
                .then((response) => {
                    this.documents = response.data.data.list;
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getType() {
            console.log("获得类型");
        },
        getFileIcon(path) {
            const extension = path.split(".").pop().toLowerCase();
            switch (extension) {
                case "pdf":
                    return require("./assets/img/PDF_icon.png"); // 使用require导入图片
                case "doc":
                case "docx":
                    return require("./assets/img/doc_icon.png");
                case "xls":
                case "xlsx":
                    return require("./assets/img/xls_icon.png"); // 假设你有对应的Excel图标
                case "txt":
                    return require("./assets/img/TXT_icon.png");
                case "ppt":
                    return require("./assets/img/ppt_icon.png");
                default:
                    return require("./assets/img/default_icon.png"); // 默认图标
            }
        },
        selectAll() {
            this.isSelectAll = true;
            for (var i = 0; i < this.documents.length; i++) {
                var path = this.documents[i].path; // 获取每个元素的 path 属性
                if (!this.selectedDocs.includes(path)) {
                    this.selectedDocs.push(path); // 将 path 添加到 selectedVideo 数组中
                }
            }
            console.log("选择所有文件", this.selectedDocs);
        },
        cancelSelectAll() {
            this.isSelectAll = false;
            this.selectedDocs = [];
            console.log("从列表中移除所有文件", this.selectedDocs);
        },
    },

    mounted() {
        this.getAllDocuments();
        EventBus.on("download", async (param) => {
            console.log("待下载文件：", this.selectedDocs);
            // 将选择的文件加入下载队列
            this.selectedDocs.forEach((file) => {
                let dlFile = {
                    fileID: file,
                    fileType: file.split(".").pop().toLowerCase(),
                    status: DOWNLOAD_STATUS.QUEUING, //排队
                    fileName: file,
                };
                this.fileList.push(dlFile);
                this.childFileList.push(dlFile);
                
            });
            console.log(this.fileList);
            //清空已经选择的文件
            this.selectedDocs = [];
            this.isSelectAll = false;
            // 执行下载操作
            for (const file of this.childFileList) {
                if (file.status != DOWNLOAD_STATUS.QUEUING) continue;
                file.status = DOWNLOAD_STATUS.DOWNLOADING;
                console.log(file.status); // 显示当前下载状态
                const config = {
                    headers: {
                        authorizationToken: this.getDeviceAuthToken(),
                    },
                };
                const url = `${this.getDeviceHost()}/documents/${file.fileID}`;
                try {
                    const response = await axios.get(
                        url,
                        { responseType: "blob" },
                        config
                    );
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    //todo：这里需要考虑两个设备的区分，ios传输的二进制数据没有后缀，安卓有
                    //安卓设备不需要加后缀
                    if (this.$store.state.deviceName === "Android") {
                        console.log("检测到为安卓设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`);
                    } else {
                        console.log("检测到为苹果设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`); // 考虑动态设置文件名
                    }
                    this.$emit("openfileDownloadHistotySmall")
                    // link.setAttribute("download", `${file.fileID}`); // 考虑动态设置文件名
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    file.status = DOWNLOAD_STATUS.SUCCESS; // 更新状态为成功
                    console.log(`文件 ${file.fileID} 下载成功`);
                } catch (error) {
                    file.status = DOWNLOAD_STATUS.FAILED; // 更新状态为失败
                    console.error(`文件 ${file.fileID} 下载失败`, error);
                }
                // this.fileList = []; // 清空已经下载的文件列表
            }
        });
    },
};
</script>
<!-- <style scoped lang="css" src="../assets/default.css" /> -->
<style scoped>
.van-grid {
    width: 6vh;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 77.5vh;
}

.alphabet {
    width: 100%;
    overflow: visible;
    box-sizing: border-box;
    border: 0px solid;
    border-bottom: 0;
}

.selected {
    background-color: #f0f0f0; /* 选中项的背景颜色 */
}
.document-item {
    list-style-type: none;
    padding: 12px;
    font-size: large;
    display: flex; /* 使用flex布局使图标和文本水平排列 */
    align-items: center; /* 垂直居中对齐图标和文本 */
    cursor: pointer; /* 鼠标悬停时显示指针，增强交互体验 */
}

.file-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px; /* 在图标和文本之间添加一些间隔 */
}
.custom-icon {
    float: right; /* 将图片向右浮动 */
    margin-left: 10px; /* 可选：添加左边距以调整图标与文本之间的间距 */
    width: 24px;
    height: 24px;    
}

</style>