<template>
    <div class="flex flex-col">
        <!-- <span class="text_8">
            <van-radio name="1">全选</van-radio>
        </span> -->
        <select-all
            :is-select-all="isSelectAll"
            @select-all="selectAll"
            @cancel-select-all="cancelSelectAll"
        />

        <div
            style="
                margin-left: 10px;
                width: 85vw;
                height: 60vh;
                border-radius: 10px;
                background-color: #ffffff;
            "
        >
            <div class="container">
                <div class="alphabet">
                    <!-- <img src="./assets/img/耳机.png" alt="Logo"> -->
                    <van-grid
                        :gutter="10"
                        :border="false"
                        :square="true"
                        :column-num="6"
                    >
                        <van-grid-item
                            v-for="(item, index) in musics"
                            :key="index"
                            :square="true"
                        >
                            <van-badge
                                color="#60d197"
                                @click="selectMusic(item)"
                            >
                                <!-- 下载音乐封面 -->
                                <van-image
                                    class="music_image"
                                    :src="
                                        require('./assets/img/thumbnail-music.png')
                                    "
                                    style="
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px;
                                    "
                                />
                                <template #content>
                                    <van-icon
                                        v-show="
                                            this.selectedMusics.includes(
                                                item
                                            )
                                        "
                                        name="success"
                                        class="badge-icon"
                                    />
                                </template>
                            </van-badge>
                            <span
                                style="
                                    display: block;
                                    font-size: 14px;
                                    -webkit-line-clamp: 3;
                                "
                                >{{ item.title }}</span
                            >
                        </van-grid-item>
                    </van-grid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SelectAll from "./selectAllTemplate.vue";
import EventBus from "@/compositions/event-bus.js";
import { DOWNLOAD_STATUS } from "@/compositions/constants.js";

export default {
    name: "DownloadMusicComponent",
    data() {
        return {
            musics: [],
            selectedMusics: [],
            isSelectAll: false,
            // fileList: [],
            childFileList: [], //下载的列表
            DOWNLOAD_STATUS,
        };
    },
    components: {
        SelectAll,
    },
    // props: {
    //   connectionURL: {
    //     type: String,
    //     required: true
    //   },
    //   token: {
    //     type: String,
    //     required: true
    //   }
    // },
    props: ["fileList"], // 接收来自父组件的 fileList

    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),

        getAllMusics() {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };
            axios
                .get(`${this.getDeviceHost()}/musics`, 
                // config
                )
                .then((response) => {
                    this.musics = response.data.data.list;
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectMusic(item) {
            if (this.selectedMusics.includes(item)) {
                const index = this.selectedMusics.indexOf(item.localID);
                this.selectedMusics.splice(index, 1);
                console.log("将选择的音乐移除列表", this.selectedMusics);
            } else {
                this.selectedMusics.push(item);
                console.log("将选择的音乐添加进列表中", this.selectedMusics);
            }
        },
        // todo
        selectAll() {
            this.isSelectAll = true;
            for (var i = 0; i < this.musics.length; i++) {
                var musicID = this.musics[i].localID; // 获取每个元素的 localID 属性
                if (!this.selectedMusics.includes(musicID)) {
                    this.selectedMusics.push(musicID); // 将 musicID 添加到 selectedMusics 数组中
                }
            }
            console.log("选择所有music", this.selectedMusics);
        },
        cancelSelectAll() {
            this.isSelectAll = false;
            this.selectedMusics = [];
            console.log("取消选择所有music", this.selectedMusics);
        },
    },
    mounted() {
        this.getAllMusics();

        EventBus.on("download", async (param) => {
            console.log("待下载文件：", this.selectedMusics);
            // 将选择的文件加入下载队列
            this.selectedMusics.forEach((file) => {
                let dlFile = {
                    fileID: file.localID,
                    fileType: "musics",
                    status: DOWNLOAD_STATUS.QUEUING, //排队
                    fileName: file.title,
                };
                this.fileList.push(dlFile);
                this.childFileList.push(dlFile);
            });
            console.log(this.fileList);
            //清空已经选择的文件
            this.selectedMusics = [];
            this.isSelectAll = false;
            // 执行下载操作
            for (const file of this.childFileList) {
                if (file.status != DOWNLOAD_STATUS.QUEUING) continue;
                file.status = DOWNLOAD_STATUS.DOWNLOADING;
                console.log(file.status); // 显示当前下载状态
                const config = {
                    headers: {
                        authorizationToken: this.getDeviceAuthToken(),
                    },
                };
                const url = `${this.getDeviceHost()}/musics/${file.fileID}`;
                try {
                    const response = await axios.get(
                        url,
                        { responseType: "blob" },
                        config
                    );
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    //todo：这里需要考虑两个设备的区分，ios传输的二进制数据没有后缀，安卓有
                    //安卓设备不需要加后缀
                    if (this.$store.state.deviceName === "Android") {
                        console.log("检测到为安卓设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`);
                    } else {
                        console.log("检测到为苹果设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}.mp3`); // 考虑动态设置文件名
                    }
                    this.$emit("openfileDownloadHistotySmall")
                    // link.setAttribute("download", `${file.fileName}.mp3`); // 考虑动态设置文件名
                    // file.fileName=`${file.fileName}.mp3`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    file.status = DOWNLOAD_STATUS.SUCCESS; // 更新状态为成功
                    console.log(`文件 ${file.fileName} 下载成功`);
                } catch (error) {
                    file.status = DOWNLOAD_STATUS.FAILED; // 更新状态为失败
                    console.error(`文件 ${file.fileName} 下载失败`, error);
                }
                // this.fileList.length = 0;// 清空已经下载的文件列表
            }
        });
    },
};
</script>
<style scoped>
.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
}
</style>
<style scoped lang="css" src="./assets/default.css" />