
<template>
    <div class="flex flex-col">
        <!-- <span class="text_8">
            <van-radio name="1">全选</van-radio>
        </span> -->
        <select-all
            :is-select-all="isSelectAll"
            @select-all="selectAll"
            @cancel-select-all="cancelSelectAll"
        />

        <div
            style="
                margin-left: 10px;
                width: 85vw;
                height: 60vh;
                border-radius: 10px;
                background-color: #ffffff;
            "
        >
            <div class="container">
                <div class="alphabet">
                    <!-- <img src="./assets/img/耳机.png" alt="Logo"> -->
                    <van-grid
                        :gutter="10"
                        :border="false"
                        :square="true"
                        :column-num="6"
                    >
                        <van-grid-item
                            v-for="(item, index) in applications"
                            :key="index"
                            :square="true"
                        >
                            <van-badge
                                color="#60d197"
                                @click="selectApks(item.apkId)"
                            >
                                <!-- 下载应用logo -->
                                <van-image
                                    class="image_1"
                                    referrerpolicy="no-referrer"
                                    :src="
                                        getDeviceHost() +
                                        '/apks/' +
                                        item.apkId +
                                        '/logo'
                                    "
                                    :style="{ marginTop: '20px' }"
                                />

                                <template #content>
                                    <van-icon
                                        v-show="
                                            this.selectedApplications.includes(
                                                item.apkId
                                            )
                                        "
                                        name="success"
                                        class="badge-icon"
                                    />
                                </template>
                            </van-badge>

                            <span style="display: block; font-size: 14px;">{{ item.name }}</span>
                            <span class="custom-cell">{{
                                formattedSize(item.size)
                            }}</span>
                        </van-grid-item>
                    </van-grid>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios, { HttpStatusCode } from "axios";
import { mapGetters } from "vuex";
import SelectAll from "./selectAllTemplate.vue";
import EventBus from "@/compositions/event-bus.js";
import { DOWNLOAD_STATUS } from '@/compositions/constants.js';

export default {
    name: "DownloadAppComponent",
    data() {
        return {
            applications: [],
            selectedApplications: [],
            isSelectAll: false,
            // fileList:[],
            childFileList: [],
            DOWNLOAD_STATUS
        };
    },
    // props: {
        // connectionURL: {
        //     type: String,
        //     required: true,
        // },
        // token: {
        //     type: String,
        //     required: true,
        // },
    // },
    props: ["fileList"], 
    computed: {},
    components: {
        SelectAll,
    },
    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),

        getAllApks() {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };
            axios
                .get(this.getDeviceHost() + "/apks", 
                // config
                )
                .then((response) => {
                    this.applications = response.data.data.list;
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        formattedSize(size) {
            const power = 2 ** 10;
            let n = 0;
            const powerLabels = { 0: "", 1: "KB", 2: "MB", 3: "GB", 4: "TB" };

            while (size > power) {
                size /= power;
                n += 1;
            }
            return `${Number(size).toFixed(2)} ${powerLabels[n]}`;

            // return `${size.toFixed(2)} ${powerLabels[n]}`;
        },
        selectApks(applicationID) {
            if (this.selectedApplications.includes(applicationID)) {
                const index = this.selectedApplications.indexOf(applicationID);
                this.selectedApplications.splice(index, 1);
                console.log("将选择的app移除列表", this.selectedApplications);
            } else {
                this.selectedApplications.push(applicationID);
                console.log(
                    "将选择的app添加进列表中",
                    this.selectedApplications
                );
            }
        },
        selectAll() {
            this.isSelectAll = true;
            for (var i = 0; i < this.applications.length; i++) {
                var applicationID = this.applications[i].apkId; // 获取每个元素的 apkId 属性
                if (!this.selectedApplications.includes(applicationID)) {
                    // 将 applicationID 添加到 selectedApplications 数组中
                    this.selectedApplications.push(applicationID);
                }
            }
            console.log("选择所有application", this.selectedApplications);
        },
        cancelSelectAll() {
            this.isSelectAll = false;
            this.selectedApplications = [];
            console.log("取消选择所有app", this.selectedApplications);
        },
    },
    mounted() {
        this.getAllApks();
        EventBus.on("download", async (param) => {
            console.log("待下载文件：", this.selectedApplications);
            // 将选择的文件加入下载队列
            this.selectedApplications.forEach((file) => {
                let dlFile = {
                    fileID: file,
                    fileType: "apks",
                    status: DOWNLOAD_STATUS.QUEUING, //排队
                };
                // console.log("dlFile",dlFile)
                this.fileList.push(dlFile);
                this.childFileList.push(dlFile);
            });
            console.log(this.fileList);
            //清空已经选择的文件
            this.selectedApplications=[];
            this.isSelectAll=false;
            // 执行下载操作
            for (const file of this.childFileList) {
                if (file.status != DOWNLOAD_STATUS.QUEUING) continue;
                file.status = DOWNLOAD_STATUS.DOWNLOADING;
                console.log(file.status); // 显示当前下载状态
                const config = {
                    headers: {
                        authorizationToken: this.getDeviceAuthToken(),
                    },
                };
                const url = `${this.getDeviceHost()}/apks/${file.fileID}`;
                try {
                    const response = await axios.get(
                        url,
                        { responseType: "blob" },
                        config
                    );
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    //设置文件名（待修改）
                    file.fileName =`${file.fileID}.apk`;

                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    //todo：这里需要考虑两个设备的区分，ios传输的二进制数据没有后缀，安卓有
                    //安卓设备不需要加后缀
                    if (this.$store.state.deviceName === "Android") {
                        console.log("检测到为安卓设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`);
                    } else {
                        console.log("检测到为苹果设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}.${type}`); // 考虑动态设置文件名
                    }
                    this.$emit("openfileDownloadHistotySmall")
                    // link.setAttribute("download", `${file.fileID}.apk`); // 考虑动态设置文件名
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    file.status = DOWNLOAD_STATUS.SUCCESS; // 更新状态为成功
                    console.log(`文件 ${file.fileID} 下载成功`);
                } catch (error) {
                    file.status = DOWNLOAD_STATUS.FAILED; // 更新状态为失败
                    console.error(`文件 ${file.fileID} 下载失败`, error);
                }
                this.fileList = []; // 清空已经下载的文件列表
            }
        });
    },
};
</script>
<style scoped>
.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
}
.custom-cell {
    color: gray; /* 文字颜色为灰色 */
    font-size: small;
}
.custom-cell2 {
    justify-content: center;
    align-items: center;
}

.image-container {
    position: relative;
    width: 100%;
    height: auto; /* 自动高度 */
    border-radius: 10px;
}

</style>
<style scoped lang="css" src="./assets/default.css" />