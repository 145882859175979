// fileTypeHandler.js

async function handleFileType(file) {
    return new Promise((resolve, reject) => {
        // 创建一个 FileReader 对象来读取文件数据
        const reader = new FileReader();
        reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const uintArray = new Uint8Array(arrayBuffer.slice(0, 4)); // 读取文件的前4个字节

            // 判断文件格式
            if (isJPEG(uintArray)) {
                resolve("jpeg");
            } else if (isPNG(uintArray)) {
                resolve("png");
            } else if (isGIF(uintArray)) {
                resolve("gif");
            } else if (isMP4(uintArray)) {
                resolve("mp4");
            } else if (isAVI(uintArray)) {
                resolve("avi");
            } else if (isMP3(uintArray)) {
                resolve("mp3");
            } else if (isWAV(uintArray)) {
                resolve("wav");
            } else if (isPDF(uintArray)) {
                resolve("pdf");
            } else if (isTXT(uintArray)) {
                resolve("txt");
            } else if (isDOC(uintArray)) {
                resolve("doc");
            } else {
                resolve(""); // 返回空字符串表示未知文件类型
            }
        };
        reader.readAsArrayBuffer(file);
    });
}

// 判断是否为 JPEG 文件
function isJPEG(uintArray) {
    return (
        uintArray[0] === 0xff &&
        uintArray[1] === 0xd8 &&
        uintArray[2] === 0xff &&
        uintArray[3] === 0xe0
    );
}

// 判断是否为 PNG 文件
function isPNG(uintArray) {
    return (
        uintArray[0] === 0x89 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x4e &&
        uintArray[3] === 0x47
    );
}

// 判断是否为 GIF 文件
function isGIF(uintArray) {
    return (
        (uintArray[0] === 0x47 &&
            uintArray[1] === 0x49 &&
            uintArray[2] === 0x46 &&
            uintArray[3] === 0x38 &&
            (uintArray[4] === 0x37 || uintArray[4] === 0x39) &&
            uintArray[5] === 0x61) ||
        (uintArray[0] === 0x47 &&
            uintArray[1] === 0x49 &&
            uintArray[2] === 0x46 &&
            uintArray[3] === 0x38 &&
            uintArray[4] === 0x39 &&
            uintArray[5] === 0x61)
    );
}

// 判断是否为 MP4 文件
function isMP4(uintArray) {
    return (
        uintArray[0] === 0x00 &&
        uintArray[1] === 0x00 &&
        uintArray[2] === 0x00 &&
        (uintArray[3] === 0x18 || uintArray[3] === 0x20) &&
        uintArray[4] === 0x66 &&
        uintArray[5] === 0x74 &&
        uintArray[6] === 0x79 &&
        uintArray[7] === 0x70
    );
}

// 判断是否为 AVI 文件
function isAVI(uintArray) {
    return (
        uintArray[0] === 0x52 &&
        uintArray[1] === 0x49 &&
        uintArray[2] === 0x46 &&
        uintArray[3] === 0x46 &&
        uintArray[8] === 0x41 &&
        uintArray[9] === 0x56 &&
        uintArray[10] === 0x49 &&
        uintArray[11] === 0x20
    );
}

// 判断是否为 MP3 文件
function isMP3(uintArray) {
    return (
        uintArray[0] === 0x49 &&
        uintArray[1] === 0x44 &&
        uintArray[2] === 0x33
    );
}

// 判断是否为 WAV 文件
function isWAV(uintArray) {
    return (
        uintArray[0] === 0x52 &&
        uintArray[1] === 0x49 &&
        uintArray[2] === 0x46 &&
        uintArray[3] === 0x46 &&
        uintArray[8] === 0x57 &&
        uintArray[9] === 0x41 &&
        uintArray[10] === 0x56 &&
        uintArray[11] === 0x45
    );
}

// 判断是否为 PDF 文件
function isPDF(uintArray) {
    return (
        uintArray[0] === 0x25 &&
        uintArray[1] === 0x50 &&
        uintArray[2] === 0x44 &&
        uintArray[3] === 0x46
    );
}

// 判断是否为 TXT 文件
function isTXT(uintArray) {
    return (
        uintArray[0] === 0x74 &&
        uintArray[1] === 0x65 &&
        uintArray[2] === 0x78 &&
        uintArray[3] === 0x74
    );
}

// 判断是否为 DOC 文件
function isDOC(uintArray) {
    return (
        uintArray[0] === 0xD0 &&
        uintArray[1] === 0xCF &&
        uintArray[2] === 0x11 &&
        uintArray[3] === 0xE0 &&
        uintArray[4] === 0xA1 &&
        uintArray[5] === 0xB1 &&
        uintArray[6] === 0x1A &&
        uintArray[7] === 0xE1
    );
}


module.exports = { handleFileType };
