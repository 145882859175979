<template>
    <div class="page flex-col justify-between">
        <div class="group_1 flex-col">
            <div class="box_1 flex-col">
                <!-- <img
                    class="image_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngc365b3eb650a7140f601d76139183ed2f0ca88d190f7008fddba05248857596c.png"
                /> -->
                <img
                    class="image_1"
                    referrerpolicy="no-referrer"
                    src="./assets/img/icon-83.5@2x.png"
                />
                <div class="block_1 flex-row justify-between">
                    <!-- <div class="group_2 flex-col"></div> -->
                    <div class="group_3 flex-col justify-between">
                        <div class="text-group_1 flex-col justify-between">
                            <span class="text_3">{{ $t("home.name") }}</span>
                            <span class="paragraph_1">
                                {{ $t("home.introduce1") }}
                                <br />
                                {{ $t("home.introduce2") }}
                            </span>
                        </div>
                        <!-- 取消立即下载弹窗 -->

                        <!-- <div class="block_2 flex-row">
              <div class="image-text_1 flex-row justify-between">
                <img
                  class="label_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8539ef296f70794487a485ded1ee49c8bba78c88b77bd5769231f933d64c7d14.png"
                /> -->
                        <!-- <span class="text-group_2">立即下载</span> -->
                        <!-- <span class="text-group_2" style="cursor: pointer;" @click="handleOpenDialog()">立即下载</span> -->

                        <!-- </div> -->
                        <!-- </div> -->
                    </div>
                </div>
                <!-- 改为img插件 -->
                <!-- <div class="block_3 flex-col"></div> -->
                <!-- <img
                    class="block_3 flex-col"
                    src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/6460fa67e6ca4aab99064c3c359e0582_mergeImage.png"
                    alt="描述图片的文字"
                /> -->
                <!-- <img
                    class="background_img"
                    src="./assets/img/background.png"
                    alt="背景图片"
                /> -->
                <img
                    class="background_img"
                    src="./assets/img/phone-img.png"
                    alt="背景图片"
                />
            </div>
        </div>
        <div class="group_4 flex-row">
            <div class="text-group_3 flex-col justify-between">
                <!-- <span class="text_4"
                    >&#64;Copyright © Fullstack
                    Technology&nbsp;(粤ICP备2021019301号). Powered by Fullstack
                    Technology</span
                > -->
                <span class="text_4">
                    &#64;Copyright © Fullstack Technology&nbsp;<a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        >(粤ICP备2021019301号-24)</a
                    >. Powered by Fullstack Technology</span
                >
                <!-- <span class="text_15">网站使用条款&nbsp;用户隐私协议</span>
                    
                <span class="text_5"
                    >{{ $t("home.website_terms") }}&nbsp;{{
                        $t("home.user_privacya_greement")
                    }}</span -->
                    <span class="text_5"
                    >
                    <a href="https://www.fsintech.com/terms2.html">{{ $t("home.website_terms") }}</a>&nbsp;
                    <a href="https://fsintech.com/privacy2.html">{{ $t("home.user_privacya_greement") }}</a>
                    
                    </span >
            </div>
        </div>
        <div class="group_5 flex-col">
            <div class="box_2 flex-col">
                <div class="text-wrapper_2 flex-row">
                    <span class="text_6">{{ $t("home.presentation") }}</span>
                </div>
                <div class="section_1 flex-row justify-between">
                    <div class="group_6 flex-col"></div>
                    <div class="text-wrapper_3 flex-col">
                        <span class="text_7">{{
                            $t("home.cross_platform")
                        }}</span>
                        <span class="paragraph_2">
                            {{ $t("home.cross_platform_1") }}
                            <br />
                            {{ $t("home.cross_platform_2") }}
                        </span>
                    </div>
                </div>
                <img
                    class="image_2"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng3d250a268a826857c45ad1888f250809891f8516626c0091c21ffd527e4030ba.png"
                />
            </div>
            <div class="box_3 flex-row">
                <div class="text-wrapper_4 flex-col">
                    <span class="text_8">{{ $t("home.multiple_data") }}</span>
                    <span class="text_9">
                        {{ $t("home.multiple_data_1") }}
                    </span>
                </div>
                <div class="block_4 flex-col"></div>
                <img
                    class="image_3"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng6a0ff56a79a88f0752daa9dcfa4d9b8d55e974373a97e2a178be06b228de5692.png"
                />
            </div>
            <div class="box_4 flex-row justify-end">
                <div class="image-wrapper_1 flex-row">
                    <img
                        class="image_4"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng8f682f3694049be0576a81a000000316f63c58923ca1b714b75f01bd7f211847.png"
                    />
                    <img
                        class="image_5"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng275e71a8b83908749edaf41900f3b0f65f080427886c74d864b56a82fc2dbd08.png"
                    />
                    <img
                        class="image_6"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngf9771f56f666b1109b4ca18dae64001bdb09d8f7454a701b94cf330cc2d1950a.png"
                    />
                </div>
                <div class="text-wrapper_5 flex-col">
                    <span class="text_10">{{
                        $t("home.fast_and_stable_transmission")
                    }}</span>
                    <span class="text_11">
                        {{ $t("home.fast_and_stable_transmission_1") }}
                    </span>
                </div>
            </div>
            <div class="box_5 flex-row">
                <div class="text-wrapper_6 flex-col">
                    <span class="text_12">{{ $t("home.safeguard") }}</span>
                    <span class="text_13">
                        {{ $t("home.safeguard_1") }}
                    </span>
                </div>
                <img
                    class="image_7"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPngbf93ad42a7b308bf089bfa38fb2f34c0bf6349f416c9b2cef763eb3a25b9b890.png"
                />
            </div>
            <div class="box_6 flex-row">
                <div
                    class="image-text_2 flex-col justify-between"
                    @click="handleOpenDialog_and()"
                >
                    <img
                        class="label_2"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng073edae64df846a664b37fb26724619b41e607d55b800aeb23a6311f848ca766.png"
                    />
                    <span class="text-group_4">{{ $t("home.Android") }}</span>
                </div>
            </div>
            <div class="box_7 flex-row">
                <div
                    class="image-text_3 flex-col justify-between"
                    @click="navigateToIOSUrl"
                >
                    <img
                        class="label_3"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng5f22d212b3577fba9205a21fc46259b4b59ef071d0e605203fe2266f59bd9de6.png"
                    />
                    <span class="text-group_5">iOS</span>
                </div>
            </div>
            <div class="box_8 flex-col">
                <div class="image-text_4 flex-col justify-between">
                    <img
                        class="label_4"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng469b866b09d5cc0c9c3b2e8086a37a7f5945e16f827b16b18174fea2308e2f2c.png"
                    />
                    <span class="text-group_6">Windows</span>
                </div>
                <span class="text_14">{{ $t("home.not_open") }}</span>
            </div>
            <div class="box_9 flex-col">
                <div class="image-text_5 flex-col justify-between">
                    <img
                        class="label_5"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngafe32818b17d2fd7b184fcf7c7ad55cb1cb08597b6c644b2713282de92b501f9.png"
                    />
                    <span class="text-group_7">Mac</span>
                </div>
                <span class="text_15">{{ $t("home.not_open") }}</span>
            </div>
        </div>
        <DownloadAPPComponent :show="isShowDialog" @close="handleCloseDialog" />
        <DownloadComponent_android
            :show="isShowDialog_and"
            @close="handleCloseDialog_and"
        />
    </div>
</template>
<script>
import DownloadAPPComponent from "@/components/downloadAPP/index.vue";
import DownloadComponent_android from "@/components/downloadAPP/index_andonly.vue";

// const showDownloadAPPtDialog = ref(false);
export default {
    name: "home",
    components: {
        DownloadAPPComponent,
        DownloadComponent_android,
    },
    data() {
        return {
            isShowDialog: false, // 或者从其他地方获取是否需要显示弹窗的状态
            isShowDialog_and: false,
        };
    },
    methods: {
        handleOpenDialog() {
            this.isShowDialog = true;
        },
        handleCloseDialog() {
            // 处理关闭弹窗逻辑
            this.isShowDialog = false;
        },
        handleOpenDialog_and() {
            this.isShowDialog_and = true;
        },
        handleCloseDialog_and() {
            // 处理关闭弹窗逻辑
            this.isShowDialog_and = false;
        },
        navigateToIOSUrl() {
            // window.location.href = "https://apps.apple.com/app/id1510942679";
            window.location.href =
                "https://apps.apple.com/cn/app/%E4%B8%80%E9%94%AE%E6%8D%A2%E6%9C%BA-%E5%85%8B%E9%9A%86%E5%8A%A9%E6%89%8B-%E6%95%B0%E6%8D%AE%E8%BF%81%E7%A7%BB/id1506745684";
        },
    },
};

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?fb0b6aee9cc5e6fd998779142ee6eb3e";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

</script>


<style scoped lang="css" src="./assets/index.css" />
<style scoped>
/* .background_img{
    width: 100px;
    height: 100px;
} */

.background_img {
    /* border-radius: 22px; */
    position: absolute;
    left: 224px;
    top: 80px;
    width: 398px;
    height: 462px;
    /* border: 8px solid rgba(255, 255, 255, 1); */
}
.paragraph_1 {
    width: 450px;
    height: 100px;
}
</style>