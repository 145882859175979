<template>
    <div class="flex flex-col">
        <!-- <span class="text_8">
            <van-radio name="1">全选</van-radio>
        </span> -->
        <select-all
            :is-select-all="isSelectAll"
            @select-all="selectAll"
            @cancel-select-all="cancelSelectAll"
        />

        <div
            style="
                margin-left: 10px;
                width: 85vw;
                height: 60vh;
                border-radius: 10px;
                background-color: #ffffff;
            "
        >
            <div class="container">
                <div class="alphabet">
                    <van-grid
                        :gutter="10"
                        :border="false"
                        :square="true"
                        :column-num="6"
                    >
                        <van-grid-item
                            v-for="(item, index) in videos"
                            :key="index"
                            :square="true"
                        >
                            <van-badge
                                color="#60d197"
                                @click="selectVideo(item.localID)"
                            >
                                <van-image
                                    class="video_image"
                                    referrerpolicy="no-referrer"
                                    :src="
                                        $store.state.deviceHost +
                                        '/videos/' +
                                        item.localID +
                                        '/thumbnail'
                                    "
                                    rel="external nofollow"
                                    style="
                                       
                                    "
                                />
                                <template #content>
                                    <van-icon
                                        v-show="
                                            selectedVideos.includes(
                                                item.localID
                                            )
                                        "
                                        name="success"
                                        class="badge-icon"
                                    />
                                </template>
                            </van-badge>
                        </van-grid-item>
                    </van-grid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
// import { mapActions } from "vuex";
import EventBus from "@/compositions/event-bus.js";
// import { Icon } from "vant";
import SelectAll from "./selectAllTemplate.vue";
import { DOWNLOAD_STATUS } from "@/compositions/constants.js";

export default {
    name: "DownloadVideoComponent",
    // computed: {
    //     // ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
    //     selectedVideos() {
    //         return this.$store.getters.getSelectedVideo;
    //     },
    // },
    components: {
        SelectAll,
    },
    data() {
        return {
            videos: [],
            selectedVideos: [],
            isSelectAll: false,
            // fileList: [],
            childFileList: [],
            DOWNLOAD_STATUS,
        };
    },
    // props: {
    // connectionURL: {
    //     type: String,
    //     required: true,
    // },
    // token: {
    //     type: String,
    //     required: true,
    // },
    // },
    props: ["fileList"],
    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),

        async getAllVideos() {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };

            let page = 1; // 初始化页码

            while (true) {
                try {
                    const response = await axios.get(
                        `${this.getDeviceHost()}/videos?page=${page}`
                        // config
                    );
                    console.log(`Page ${page}:`, response.data);

                    // 合并当前页的照片到videos数组中
                    this.videos = [...this.videos, ...response.data.data.list];

                    // 当响应数组为空时退出循环
                    if (response.data.data.list.length === 0) {
                        console.log(
                            "No more videos to load, exiting the loop."
                        );
                        break;
                    }

                    // 准备获取下一页的数据
                    page++;
                } catch (error) {
                    console.error(error);
                    // 可以在这里添加更复杂的错误处理逻辑
                    // 在遇到错误时，可能需要决定是否继续尝试获取下一页的数据或是退出循环
                    break; // 出错时跳出循环
                }
            }
        },
        selectVideo(videoID) {
            if (this.selectedVideos.includes(videoID)) {
                const index = this.selectedVideos.indexOf(videoID);
                this.selectedVideos.splice(index, 1);
                console.log("将选择的视频移除列表", this.selectedVideos);
            } else {
                this.selectedVideos.push(videoID);
                console.log("将选择的视频添加进列表中", this.selectedVideos);
            }
        },

        selectAll() {
            this.isSelectAll = true;
            for (var i = 0; i < this.videos.length; i++) {
                var videoID = this.videos[i].localID; // 获取每个元素的 localID 属性
                if (!this.selectedVideos.includes(videoID)) {
                    this.selectedVideos.push(videoID); // 将 videoID 添加到 selectedVideos 数组中
                }
            }
            console.log("选择所有video", this.selectedVideos);
        },
        cancelSelectAll() {
            this.isSelectAll = false;
            this.selectedVideos = [];
            console.log("取消选择所有video", this.selectedVideos);
        },
    },
    mounted() {
        this.getAllVideos();

        EventBus.on("download", async (param) => {
            console.log("待下载文件：", this.selectedVideos);
            // 将选择的文件加入下载队列
            this.selectedVideos.forEach((file) => {
                let dlFile = {
                    fileID: file,
                    fileType: "videos",
                    status: DOWNLOAD_STATUS.QUEUING, //排队
                    fileName: file,
                };
                console.log("dlFile", dlFile);
                this.fileList.push(dlFile);
                this.childFileList.push(dlFile);
                // this.$emit("openfileUploadedHistotySmall")
            });
            console.log(this.fileList);
            //清空已经选择的文件
            this.selectedVideos = [];
            this.selectAll = false;
            // 执行下载操作
            for (const file of this.childFileList) {
                if (file.status != DOWNLOAD_STATUS.QUEUING) continue;
                file.status = DOWNLOAD_STATUS.DOWNLOADING;
                console.log(file.status); // 显示当前下载状态
                const config = {
                    headers: {
                        authorizationToken: this.getDeviceAuthToken(),
                    },
                };
                const url = `${this.getDeviceHost()}/videos/${file.fileID}`;
                try {
                    const response = await axios.get(
                        url,
                        { responseType: "blob" }
                        // config
                    );
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = downloadUrl;

                    //todo：这里需要考虑两个设备的区分，ios传输的二进制数据没有后缀，安卓有
                    //安卓设备不需要加后缀
                    if (this.$store.state.deviceName === "Android") {
                        console.log("检测到为安卓设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`);
                    } else {
                        console.log("检测到为苹果设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}.mp4`); // 考虑动态设置文件名
                    }
                    this.$emit("openfileDownloadHistotySmall")
                    // link.setAttribute("download", `${file.fileID}.mp4`); // 考虑动态设置文件名

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    file.status = DOWNLOAD_STATUS.SUCCESS; // 更新状态为成功
                    console.log(`文件 ${file.fileID} 下载成功`);
                } catch (error) {
                    file.status = DOWNLOAD_STATUS.FAILED; // 更新状态为失败
                    console.error(`文件 ${file.fileID} 下载失败`, error);
                }
                // this.fileList = []; // 清空已经下载的文件列表
            }
        });
    },
};
</script>
<style scoped>
.video_image{
    width: 120px;
    height: 120px;
    border-radius: 10px;
}
.badge-icon {
    display: block;
    font-size: 10px;
    line-height: 16px;
    /* color: aquamarine; */
    /* background-color: #f5f9fa; */
}

.alphabet {
    width: 100%;
    overflow: visible;
    box-sizing: border-box;
    border: 0px solid;
    border-bottom: 0;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 60vh;
    /* width: 100%; */
}
</style>
<!-- <style scoped lang="css" src="./assets/photo.css" /> -->
<!-- <style scoped lang="css" src="./assets/default.css" /> -->