<template>
  <div>
    <nav >
      <div class=" flex-row top_tab" style="">
        <!-- <img class="icon_img"  src="icon-1024.png" style="cursor: pointer; border-radius: 10px;" @click="$router.push('/')"/>&nbsp; -->
        <img class="icon_img"  src="./assets/icon-83.5@2x.png" style="cursor: pointer; border-radius: 10px;" @click="$router.push('/')"/>&nbsp;
        <span class="text_0 text_nav"><router-link to="/">EasSync</router-link></span>
        <span class="text_1 text_nav" style="cursor: pointer;" @click="openConnectDialog($event)">{{ $t('tab1.transfer') }}</span>
        <!-- <span class="text_1 text_nav"><router-link to="/transfer">在线传输</router-link></span> -->
        <!-- <span class="text_2 text_nav" style="cursor: pointer;" @click="openDownloadAPPtDialog($event)">{{ $t('tab1.download') }}</span> -->
        <!-- <span class="text_0_0 text_nav"><router-link to="/help">帮助</router-link></span> -->
      </div>
    </nav>
    <div>
      <router-view/>
    </div>
    <ConnecterComponent :show="showConnectDialog" @close="closeConnectDialog" />
    <DownloadAPPComponent :show="showDownloadAPPtDialog" @close="closeDownloadAPPtDialog" />

  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import ConnecterComponent from '@/components/connecter/index.vue'
import DownloadAPPComponent from '@/components/downloadAPP/index.vue'


export default {
  name: 'APPVUE',
  data() {
    return {
      status: {
        isShowPublish: false
      }
    }
  },
  components: {
    ConnecterComponent,
    DownloadAPPComponent
  },
  setup() {
    const showConnectDialog = ref(false);
    const showDownloadAPPtDialog = ref(false);
    const router = useRouter();

    const openConnectDialog = (event) => {
      event.stopPropagation();
      showConnectDialog.value = true;
      return false;
    };

    const closeConnectDialog = (redirectToTransfer) => {
      if (redirectToTransfer) {
        router.push({ name: 'transfer'});
      }
      showConnectDialog.value = false;
    };

    const openDownloadAPPtDialog = (event) => {
        //  切换下载页面
        router.push({ name: "transfer" });
    //   event.stopPropagation();
    //   showDownloadAPPtDialog.value = true;
    //   return false;
    };

    const closeDownloadAPPtDialog = (redirectToTransfer) => {
      if (redirectToTransfer) {
        router.push({ name: 'transfer'});
      }
      showDownloadAPPtDialog.value = false;
      console.log(showDownloadAPPtDialog.value)

    };

    return {
      showConnectDialog,
      showDownloadAPPtDialog,
      openConnectDialog,
      closeConnectDialog,
      openDownloadAPPtDialog,
      closeDownloadAPPtDialog
    };
  }
}
</script>
<style lang="css" src="./assets/common.css">
</style>

<style scoped>
.top_tab{
    height: 48px;
    width: 100px;
    margin-left: 15px;
}

.icon_img{
    width:48px;
    height:48px;
}

div{
    width: 1440px;
}
nav {
  padding: 10px;
  width: 1440px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #69cdaa;
}

.text_nav {
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text_0 {
  width: 64px;
  margin: 15px 0 0 0px;
}

.text_1 {
  width: 64px;
  margin: 15px 0 0 1084px;
}

.text_2 {
  width: 32px;
  margin: 15px 0 0 20px;
}

.text_0_0 {
  width: 32px;
  margin: 15px 0 0 20px;
}
</style>
