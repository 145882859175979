// 2、创建英文语言包对象
export default {
    menus: {
        home: 'Home',
        download: 'Download'
    },
    tab1: {
        transfer: 'Transfer',
        download: 'Download',
        sendtophone: 'Send',
        savetopc: 'Save',
        history: 'History',
        connect: `Connecting `,
        disconnect:'Disconnect'
    },
    tab2: {
        photo:  'photo',
        video : 'video',
        music  : 'music',
        application : 'application',
        document: 'document',
    },
    dialog: {
        match : 'Please use the mobile APP to scan the QR code for matching',
        
    },
    home: {
        name: 'Phone Transfer Assistant',
        introduce1:'Face-to-face, one-on-one, zero data usage',
        introduce2:'One-click solution to your phone transfer issues! No more worries about switching phones',
        btn_download: 'Download Now',
        website_terms: 'Website Terms of Use',
        user_privacya_greement: 'User Privacy Agreement',
        presentation:  'Product Introduction',
        cross_platform :'Cross-platform Transfer',
        cross_platform_1:'Phone Transfer Assistant supports both iOS and Android operating systems,',
        cross_platform_2:'allowing you to freely transfer data between different phones. Whether it is from iOS to Android or from Android to iOS, quick migration can be achieved.',
        multiple_data: 'Support for Multiple Data Types',
        multiple_data_1: 'In addition to common photos, videos, music, and contacts, Phone Transfer Assistant also supports the transfer of calendars, applications, and various files, ensuring that you can migrate data completely without missing any important information.',
        fast_and_stable_transmission: 'Fast and Stable Transmission',
        fast_and_stable_transmission_1:'The application adopts efficient data transmission technology to ensure fast and stable transfer process. No matter how large your data is, Phone Transfer Assistant can efficiently complete the transfer, saving you time.',
        safeguard:'Security Protection',
        safeguard_1:'All data transmissions are encrypted to ensure that your personal information and privacy will not be compromised. Phone Transfer Assistant is committed to providing secure and reliable data transfer services.',
        Android: 'Android',
        not_open: '(Not available yet)'
        }


}