<template>
    <div class="flex flex-col flex_111" style="">
        <div
            class="flex flex-row"
            style="border-radius: 10px; height: 30px; width: 40px"
        >
            <div
                v-show="isSelectAll == false"
                style="
                    font-size: 12px;
                    margin-top: 5px;
                    margin-left: 20px;
                    width: 100%;
                "
                class="select_text"
                @click="selectAll"
            >
                <img src="./assets/img/icon-unselected.png" />&nbsp;<span
                    class="select_text_font"
                    >全选</span
                >
            </div>
            <div
                v-show="isSelectAll == true"
                style="
                    font-size: 12px;
                    margin-top: 5px;
                    margin-left: 20px;
                    width: 100%;
                "
                class="select_text"
                @click="cancelSelectAll"
            >
                <img src="./assets/img/icon-selected.png" />&nbsp;<span
                    class="select_text_font"
                    >取消全选</span
                >
            </div>
        </div>
        <div class="flex-row" style="border-radius: 10px">
            <!-- 相册框 -->
            <div
                class="flex-col"
                style="
                    width: 10vw;
                    height: 60vh;
                    border-radius: 10px;
                    background-color: #ffffff;
                    overflow: auto;
                "
            >
                <div
                    class="flex-col justify-between"
                    style="cursor: pointer"
                    @click="getPhotos(item)"
                    v-for="(item, index) in albums"
                    :key="index"
                >
                    <div
                        class="image-container"
                        tabindex="0"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center; /* 纵向居中 */
                            align-items: center; /* 横向居中 */
                            position: relative;
                            width: 10vw;
                            height: 15vh;
                            border-radius: 10px;
                            margin-bottom: 5px; /* 上边距为10px */
                        "
                    >
                        <!-- 相册封面图 -->
                        <img
                            class="image_1"
                            style="margin-top: 5px"
                            referrerpolicy="no-referrer"
                            :src="
                                $store.state.deviceHost +
                                '/photos/' +
                                item.localID +
                                '/thumbnail'
                            "
                        />

                        <span class="album_text" style="display: block"
                            >{{ item.title }}({{ item.photoCount }})</span
                        >
                    </div>
                </div>
            </div>
            <!-- 相片框 -->
            <div class="photo_container" style="overflow: auto">
                <div class="">
                    <div class="alphabet">
                        <van-grid
                            :gutter="10"
                            :border="false"
                            :square="true"
                            :column-num="6"
                        >
                            <van-grid-item
                                v-for="(item, index) in photos"
                                :key="index"
                                :square="true"
                            >
                                <van-badge
                                    color="#60d197"
                                    @click="selectPhoto(item.localID)"
                                >
                                    <van-image
                                        :src="
                                            $store.state.deviceHost +
                                            '/photos/' +
                                            item.localID +
                                            '/thumbnail'
                                        "
                                        rel="external nofollow"
                                        class="van_img_size"
                                    />
                                    <template #content>
                                        <van-icon
                                            v-show="
                                                this.selectedPhotos.includes(
                                                    item.localID
                                                )
                                            "
                                            name="success"
                                            class="badge-icon"
                                        />
                                    </template>
                                </van-badge>
                                <!-- <a :href="$store.state.deviceHost + '/photos/' + item.localID" target="_blank" > -->

                                <!-- </a> -->
                                <!-- <img style="width: 100px; height: 100px; background: #F6F6F6; border-radius: 8px;" referrerpolicy="no-referrer" :src="connectionURL + '/photos/' + item.localID + '/thumbnail' "/> -->
                            </van-grid-item>
                        </van-grid>
                    </div>
                </div>

                <!-- <div class="image-text_5 flex-col justify-between"  >
         
        </div> -->
            </div>
        </div>
    </div>
</template>
<script scoped>
import axios from "axios";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import { mapMutations } from 'vuex';

import EventBus from "@/compositions/event-bus.js";
// import { Icon } from "vant";
import { DOWNLOAD_STATUS } from "@/compositions/constants.js";
import { handleFileType } from "./fileTypeHandler.js";

export default {
    name: "DownloadPhotoComponent",
    data() {
        return {
            albums: [],
            photos: [],
            isSelectAll: false,
            selectedPhotos: [],
            childFileList: [],
            DOWNLOAD_STATUS,
        };
    },
    // props: {
    //   donwloadSelectedFiles: {
    //     type: Boolean,
    //     required: true
    //   }
    // },
    props: ["fileList"], // 接收来自父组件的 fileList
 
    computed: {
        // ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        // selectedPhotos() {
        //     //查已经选择的照片
        //     return this.$store.getters.getSelectedPhotos;
        // },
    },
    methods: {
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        getAllAlbums() {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };
            axios
                // .get(this.getDeviceHost() + "/albums", config)
                .get(this.getDeviceHost() + "/albums")
                .then((response) => {
                    // this.albums = response.data.data.list;
                    this.albums = response.data.data.list.map((album) => ({
                        ...album,
                        selected: false, // 添加 selected 属性并初始化为 false
                    }));
                    console.log("response", response);
                    console.log(this.albums);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async getPhotos(item) {
            // console.log("获取照片", item);
            // console.log("item.albumID", item.albumID);

            // 每页显示的照片数量
            const photosPerPage = 50;
            // 计算总页数
            const totalPages = Math.ceil(item.photoCount / photosPerPage);

            // 初始化photos数组用于存储所有照片
            this.photos = [];

            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };

            for (let page = 1; page <= totalPages; page++) {
                try {
                    const response = await axios.get(
                        `${this.getDeviceHost()}/albums/${
                            item.albumID
                        }/photos?page=${page}`
                        // config
                    );
                    // 合并当前页的照片到photos数组中
                    this.photos = [...this.photos, ...response.data.data.list];
                    console.log(`Page ${page}:`, response.data);
                } catch (error) {
                    console.error(error);
                    // 可以在这里添加更复杂的错误处理逻辑
                    // 在遇到错误时，可能需要决定是否继续尝试获取下一页的数据或是退出循环
                    break; // 出错时跳出循环
                }
            }

            // 所有页处理完成后，this.photos将包含所有照片
            console.log("All photos have been retrieved.");
        },
        selectPhoto(photoID) {
            if (this.selectedPhotos.includes(photoID)) {
                const index = this.selectedPhotos.indexOf(photoID);
                this.selectedPhotos.splice(index, 1);
                console.log("将选择的照片移除列表", this.selectedPhotos);
            } else {
                this.selectedPhotos.push(photoID);
                console.log("将选择的照片添加进列表中", this.selectedPhotos);
            }
        },
        selectAll() {
            this.isSelectAll = true;
            for (var i = 0; i < this.photos.length; i++) {
                var photoID = this.photos[i].localID; // 获取每个元素的 localID 属性
                if (!this.selectedPhotos.includes(photoID)) {
                    this.selectedPhotos.push(photoID); // 将 photoID 添加到 selectedVideo 数组中
                }
            }
            console.log("选择所有photo", this.selectedPhotos);
        },
        cancelSelectAll() {
            this.isSelectAll = false;
            this.selectedPhotos = [];
            console.log("从列表中移除所有相片", this.selectedPhotos);
        },
    },
    mounted() {
        this.getAllAlbums();
        EventBus.on("download", async (param) => {
            console.log("待下载文件：", this.selectedPhotos);
            // 将选择的文件加入下载队列
            this.selectedPhotos.forEach((file) => {
                let dlFile = {
                    fileID: file,
                    fileType: "photos",
                    status: DOWNLOAD_STATUS.QUEUING, //排队
                    fileName: file,
                };
                this.fileList.push(dlFile);
                this.childFileList.push(dlFile);
            });
            // console.log(this.fileList);
            //清空已经选择的文件
            this.selectedPhotos = [];
            this.isSelectAlls = false;
            // 执行下载操作
            for (const file of this.childFileList) {
                if (file.status == DOWNLOAD_STATUS.SUCCESS) continue;
                file.status = DOWNLOAD_STATUS.DOWNLOADING;
                console.log(file.status); // 显示当前下载状态
                const config = {
                    headers: {
                        authorizationToken: this.getDeviceAuthToken(),
                    },
                };
                const url = `${this.getDeviceHost()}/photos/${file.fileID}`;
                try {
                    const response = await axios.get(
                        url,
                        { responseType: "blob" },
                        config
                    );
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    // console.log("返回的二进制数据", response.data);
                    // 判断格式
                    const type = await handleFileType(response.data);
                    file.fileName = `${file.fileID}.${type}`; // 设置 fileType 属性值为获取的文件类型

                    const link = document.createElement("a");
                    link.href = downloadUrl;

                    //todo：这里需要考虑两个设备的区分，ios传输的二进制数据没有后缀，安卓有
                    //安卓设备不需要加后缀
                    if (this.$store.state.deviceName === "Android") {
                        console.log("检测到为安卓设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}`);
                    } else {
                        console.log("检测到为苹果设备",this.$store.state.deviceName)
                        link.setAttribute("download", `${file.fileID}.${type}`); // 考虑动态设置文件名
                    }
                    this.$emit("openfileDownloadHistotySmall")
                    // link.setAttribute("download", `${file.fileID}`); // 删除后缀

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    file.status = DOWNLOAD_STATUS.SUCCESS; // 更新状态为成功
                    console.log(`文件 ${file.fileID} 下载成功`);
                    

                } catch (error) {
                    file.status = DOWNLOAD_STATUS.FAILED; // 更新状态为失败
                    console.error(`文件 ${file.fileID} 下载失败`, error);
                }
                // this.fileList = []; // 清空已经下载的文件列表
                console.log(this.fileList);
            }
        });
    },

    // watch: {
    //   donwloadSelectedFiles: {
    //     handler(){
    //       console.log('the items have changed');
    //     },
    //     deep: true
    //   }
    // }
};
</script>
<style scoped>

.album_block {
    width: 108px;
    height: 112px;
    background: #f5f9fa;
}

.album_text {
    width: 71px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 17px;
    text-align: center;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 77.5vh;
    width: 70vw;
}

.alphabet {
    width: 100%;
    overflow: visible;
    box-sizing: border-box;
    border: 0px solid;
    border-bottom: 0;
}

.badge-icon {
    display: block;
    font-size: 10px;
    line-height: 16px;
}

.select_text {
    display: flex;
    align-items: flex-start;
}
.select_text_font {
    font-size: 15px;
}

.image-container:focus {
    background-color: #f6f9fa;
}
.container {
    height: calc(60vh);
}
.photo_container {
    margin-left: 10px;
    width: 65vw;
    height: 60vh;
    border-radius: 10px;
    background-color: #ffffff;
}
.van_img_size {
    width: 120px;
    height: 120px;
}
</style>

<style scoped lang="css" src="./assets/photo.css" />