<!-- 全选组件 -->
<template>
    <div class="flex flex-row btn_all" style="">
      <div
        v-show="!isSelectAll"
        style="
          font-size: 12px;
          margin-top: 5px;
          margin-left: 20px;
          width: 100%;
        "
        class="select_text"
        @click="selectAll"
      >
        <img src="./assets/img/icon-unselected.png" />&nbsp;<span class="select_text_font">全选</span>
      </div>
      <div
        v-show="isSelectAll"
        style="
          font-size: 12px;
          margin-top: 5px;
          margin-left: 20px;
          width: 100%;
        "
        class="select_text"
        @click="cancelSelectAll"
      >
        <img src="./assets/img/icon-selected.png" />&nbsp;<span class="select_text_font">取消全选</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:"SelectAll",
    props: {
      isSelectAll: Boolean,
    },
    data() {
      return {};
    },
    methods: {
      selectAll() {
        this.$emit("select-all");
      },
      cancelSelectAll() {
        this.$emit("cancel-select-all");
      },
    },
  };
  </script>
  
  <style>
  .select_text {
    display: flex;
    align-items: flex-start;
  }
  .select_text_font {
    font-size: 15px;
  }
  .btn_all{
    border-radius: 10px; height: 30px
  }
  </style>
  