<template>
    <div class="dialog-overlay" v-if="show" ref="dialog">
      <div class="dialog-content">            
        <div class="close-btn-div">
            <img class="close-btn" referrerpolicy="no-referrer" src="./assets/img/close-btn.png" style="cursor: pointer;" @click="closeDownloadAPPtDialog"/>
        </div>
 
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/华为@3x.png" alt="华为">
            <span class="my-text">华为应用市场</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('https://url.cloud.huawei.com/oE8WdjBiV2')">下载</button>
          </div>
        </div>
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/vivo@3x.png" alt="vivo">
            <span class="my-text">VIVO</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=3138063&resource=301&source=7')">下载</button>
          </div>
        </div>
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/oppo@3x.png" alt="OPPO">
            <span class="my-text">OPPO</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('https://app.cdo.oppomobile.com/home/detail?app_id=30556467')">下载</button>
          </div>
        </div>
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/xiaomi.png" alt="小米">
            <span class="my-text">小米</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('http://app.xiaomi.com/detail/1391089')">下载</button>
          </div>
        </div>
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/应用宝@3x.png" alt="应用宝">
            <span class="my-text">应用宝</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('http://a.app.qq.com/o/simple.jsp?pkgname=com.datacopy.app&fromcase=70051&g_f=1182517&scenevia=XQYFX')">下载</button>
          </div>
        </div>
        <div class="my-row">
          <div class="my-col">
            <img class="my-icon" src="./assets/img/GooglePlay.png" alt="google">
            <span class="my-text">Google Play</span>
          </div>
          <div class="my-col-btn">
            <button class="my-btn" @click="gotoUrl('https://play.google.com/store/apps/details?id=com.datacopy.app')">下载</button> 
          </div>
  </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    name: "myDownloadComponent_android",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        gotoUrl(url) {
            window.open(url, "_blank");
        },
        closeDownloadAPPtDialog() {
            this.$emit("close");
        },
        
    },
};
</script>
  
  <style scoped>
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    width: 350px; /* 修改对话框宽度 */
}

.my-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.my-col {
    flex-basis: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.my-col-btn {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.my-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.my-text {
    font-size: 16px;
    color: black;
}

.my-btn {
    border: 1px solid #69cdaa;
    background-color: #69cdaa;
    color: white;
    padding: 10px 28px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.my-btn:hover {
    background-color: white;
    color: #69cdaa;
    border: 1px solid #69cdaa;
}

.close-btn {
    /* align-self: center; */
    width: 30px;
    height: 30px;
    cursor: pointer;
    justify-content: flex-end;
}
.close-btn-div {
    height: 30px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: flex-end;
}


</style>
  