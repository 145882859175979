import { createStore } from 'vuex'
export default createStore({
    state: {
        //默认ip
        deviceHost: `http://localhost:8888`,
        deviceAuthToken: "123456",
        deviceName: `未命名设备`,
    },
    getters: {
        getDeviceHost(state) {
            console.log("window.dasConfig.ip", window.dasConfig.ip)
            console.log("getDeviceHost: " + state.deviceHost)
            return state.deviceHost
        },
        getDeviceAuthToken(state) {
            console.log("getDeviceAuthToken: " + state.deviceAuthToken)
            return state.deviceAuthToken
        },
        getDeviceName(state) {
            console.log("getDeviceName: " + state.getDeviceName)
            return state.deviceName
        }

    },
    mutations: {
        setDeviceHost(state, deviceHost) {

            console.log("setDeviceHost: " + deviceHost)
            console.log("todo：执行重定向")
            // const redirectUrl = `https://api.eassync.com?ip=${deviceHost}`;
            // 执行重定向
            // window.location.href = redirectUrl;
            state.deviceHost = "http://" + deviceHost + ":8888"  //设置ip
        },
        setDeviceAuthToken(state, deviceAuthToken) {
            console.log("setDeviceAuthToken: " + deviceAuthToken)
            state.deviceAuthToken = deviceAuthToken
        },
        setDeviceName(state, deviceName) {
            console.log("setDeviceName:" + deviceName);
            state.deviceName = deviceName;
            console.log(state.deviceName);
        },

    },
})
