<template>
    <div
        :class="{
            'dialog-overlay-small': isSmall,
            'dialog-overlay-large': !isSmall,
        }"
        v-if="show"
        ref="dialog"
    >
        <div class="dialog-content">
            <div class="box_6 flex-row justify-between">
                <span class="text_14">历史记录</span>
                <div class="margin-left: auto;">
                    <img
                        class="label_11"
                        style="cursor: pointer; float: right"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng17e800466e55a1f2818158d22c20bb7d5148a3c4798e8d7220337f23900d1df8.png"
                        @click="close"
                    />
                    <img
                        class="label_123"
                        style="cursor: pointer; float: right"
                        src="static/image/changSizeIcon.jpeg"
                        @click="zoomChange"
                    />
                   
                </div>
            </div>
            <!-- Tab栏 -->
            <div class="tabs">
                <div
                    class="tab"
                    :class="{ active: activeTab === 'history' }"
                    @click="setActiveTab('history')"
                >
                    历史发送
                </div>
                <div
                    class="tab"
                    :class="{ active: activeTab === 'download' }"
                    @click="setActiveTab('download')"
                >
                    本地下载
                </div>
            </div>

            <!-- 根据激活的Tab显示内容 -->
            <div class="dialog-content" v-if="activeTab === 'history'">
                <!-- 历史发送的内容 -->
                <div
                    ref="dialogElement"
                    style=""
                    :class="{
                        'dialog-small': isSmall,
                        'dialog-large': !isSmall,
                    }"
                >
                    <div
                        class="box_8 flex-row justify-between"
                        v-for="(file, index) in UploadFileList"
                        :key="index"
                    >
                        <div class="image-text_6 flex-row justify-between">
                            <!-- 缩略图 -->
                            <img
                                class="image_3"
                                referrerpolicy="no-referrer"
                                :src="getFileCover(file)"
                            />
                            <div class="text-group_6 flex-col justify-between">
                                <span class="text_18">{{ file.name }}</span>
                            </div>
                        </div>
                        <div class="image-text_7 flex-row justify-between">
                            <!-- 这里的 src 应替换为真实的状态图标路径，根据实际情况动态选择 -->
                            <img
                                class="label_13"
                                referrerpolicy="no-referrer"
                                :src="getFileIcon(file.status)"
                            />
                            <span class="text-group_7">{{ file.status }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog-content" v-if="activeTab === 'download'">
                <!-- 本地下载的内容 -->
                <div
                    style=""
                    :class="{
                        'dialog-small': isSmall,
                        'dialog-large': !isSmall,
                    }"
                >
                    <div
                        class="box_8 flex-row justify-between"
                        v-for="(file, index) in fileList"
                        :key="index"
                    >
                        <div class="image-text_6 flex-row justify-between">
                            <!-- 缩略图 -->
                            <img
                                class="image_3"
                                referrerpolicy="no-referrer"
                                :src="getFileCover(file)"
                            />
                            <div class="text-group_6 flex-col justify-between">
                                <!-- 显示名称 -->
                                <span class="text_18">{{ file.fileName }}</span>
                            </div>
                        </div>
                        <div class="image-text_7 flex-row justify-between">
                            <!-- 这里的 src 应替换为真实的状态图标路径，根据实际情况动态选择 -->
                            <img
                                class="label_13"
                                referrerpolicy="no-referrer"
                                :src="getFileIcon(file.status)"
                            />
                            <span class="text-group_7">{{ file.status }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script scoped>
import { mapGetters } from "vuex";

export default {
    name: "HistoryComponent",
    data() {
        return {
            constants: {},
            show: false, // 初始化show属性为false
            isSmall: true, // 新增变量用于标识弹窗尺寸
        };
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        fileList: {
            type: Array, // 假设 fileList 是一个数组
            default: () => [], // 提供一个默认值，如果父组件没有传入 fileList，就使用空数组
        },
        UploadFileList: {
            type: Array, // 假设 fileList 是一个数组
            default: () => [], // 提供一个默认值，如果父组件没有传入 fileList，就使用空数组
        },
        isSmall: {
            type: Boolean, //true默认打开小窗口
            required: true,
        },
        activeTab: {
            default: "history",
        },
    },
    // props: ['fileList'], // 接收来自父组件的 fileList
    // mounted() {
    //     this.$nextTick(() => {
    //   this.scrollToBottom();
    // });  },

    methods: {
        //     scrollToBottom() {
        //   const dialogElement = this.$refs.dialogElement;
        //   dialogElement.scrollTop = dialogElement.scrollHeight;
        // },
        ...mapGetters(["getDeviceHost", "getDeviceAuthToken"]),
        setActiveTab(tab) {
            if (tab === "history") {
                this.$emit("setActiveTabHistory");
            }
            if (tab === "download") {
                this.$emit("setActiveTabDownload");
            }
            console.log(this.UploadFileList);
            console.log(tab);
        },
        close() {
            this.$emit("close");
        },
        getFileIcon(status) {
            switch (status) {
                case "queuing":
                case "downloading":
                case "uploading":
                    return require("./assets/img/SketchPng6807aa3a5eb5e1cb2d6c33654b61f41e48dc3ee5509284bdb7c2858d9c807774.png");
                case "success":
                    return require("./assets/img/SketchPng217d556269a8e2ab6435ec322715f3be79e2864f12b9129b245c6c3d572d9495.png"); // 假设你有对应的Excel图标
                case "failed":
                    return require("./assets/img/SketchPng62080799c234d084f82dbf2ee6a3b5e90142bd92536e3611ff8909a1a9b46b8b.png");
            }
        },

        getFileCover(file) {
            const config = {
                headers: {
                    authorizationToken: this.getDeviceAuthToken(),
                },
            };
            console.log(file);
            switch (file.fileType) {
                case "photos":
                    return (
                        this.getDeviceHost() +
                        "/photos/" +
                        file.fileID +
                        "/thumbnail"
                    );
                case "png":
                case "jpg":
                case "jpeg":
                    return file.staffPhoto;
                case "videos":
                    return (
                        this.getDeviceHost() +
                        "/videos/" +
                        file.fileID +
                        "/thumbnail"
                    );
                // return file.staffPhoto;
                case "mp4":
                    //获取封面
                    return file.staffPhoto;
                case "musics":
                case "mp3":
                    return require("./assets/img/thumbnail-music.png");
                case "apks":
                    return require("./assets/img/icron-eassync.png");
                //默认情况-文件
                default:
                    return require("./assets/img/default_icon.png");
            }
        },
        zoomChange() {
            this.$emit("changesize");
        },
    },
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.changesize_icon {
    width: 20px;
    height: 20px;
}
.dialog-overlay-large {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: transform 0.3s ease;
}
.dialog-overlay-small {
    position: fixed;
    top: 70vh;
    left: 54vw;

    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.7);
    transition: transform 0.3s ease;
}

.dialog-content {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 10px 28px;
    font-size: 16px;
    cursor: pointer;
}

/* Gray */
.default {
    border-color: #e7e7e7;
    color: black;
}

.default:hover {
    background: #e7e7e7;
}

.tabs {
    display: flex;
    justify-content: space-around;
    padding: 5px; /* 减少内边距 */
    background-color: #f0f0f0;
}

.tab {
    cursor: pointer;
    padding: 5px 10px; /* 减少内边距 */
    font-size: 14px; /* 减小字体大小 */
    margin: 0 5px; /* 调整间距 */
    border-radius: 4px; /* 可以调整为更小的值以适应小尺寸 */
}

.tab.active {
    background-color: #d0d0d0;
}
.text_18 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .dialog-content {

    transform: scale(1); 
    transition: transform 0.3s ease; 
}*/

.dialog-small {
    height: 100px;
    overflow-y: auto;
}

.dialog-large {
    overflow-y: auto;
    height: 450px;
}
</style>