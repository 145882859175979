<template>
  <div class="dialog-overlay" v-if="show" ref="dialog">
    <div class="dialog-content">
      <div>
        <span style="font-size: 16px">{{ $t('dialog.match') }}</span>&nbsp;&nbsp;
        <img class="label_11" referrerpolicy="no-referrer" src="./assets/img/close-btn.png" style="cursor: pointer;" @click="closeConnectDialog"/>
      </div>
      <div style="width: 320px; height: 320px; display: table-cell; vertical-align: middle; text-align: center;">
        <van-loading v-if="connectionURL == ''" size="50px" type="spinner"/>
        <!-- <vue-qr v-if="connectionURL != ''" logoSrc="icon-1024.png" :text="myText" :logoScale="0.3" :size="300" qid="testid"></vue-qr>   -->
        <vue-qr v-if="connectionURL != ''"   :text="connectionURL" :logoScale="0.3" :size="300" qid="testid"></vue-qr>  
      </div>
    </div>
  </div>
</template>
  
<script>
  import axios from 'axios';
  import { mapMutations } from 'vuex';
  import VueQr from 'vue-qr/src/packages/vue-qr.vue'

  export default {
    name: 'ConnectComponent',
    data() {
        return {
            timer: '',
            connectionURL: '',
            myText: "正在连接...",
            dataObj:{
                logo:require('./assets/img/icron-eassync.png')
            }
        }
    },
    components: {
      VueQr
    },
    props: {
      show: {
        type: Boolean,
        required: true
      }
    },
    mounted() {
        this.$watch('show', (newValue) => {
            if (newValue) {
                this.generateQRCode();
            }
        });
    },
    methods: {
        ...mapMutations(["setDeviceHost", "setDeviceAuthToken","setDeviceName"]),
        generateQRCode() {
            axios
                .post('http://ws.fsintech.com:18084/api/connections')
                // .post('http://localhost:8001/api/connections')
                .then(response => {
                    this.connectionURL = response.data.data.url;
                    this.timer = setInterval(this.checkEstabishment, 2000);
                    console.log( "请求地址",this.connectionURL);
                })
                .catch(error =>{
                    console.log(error);
                })
        },
        closeConnectDialog() {
            this.connectionURL = '';
            clearInterval(this.timer);
            this.$emit('close');
        },
        checkEstabishment() {
          if (this.connectionURL != '') {
            axios
                .get(this.connectionURL)
                .then(response => {
                    if (response.data.code == 0) {
                      let host = response.data.data.host;
                      let token = response.data.data.token;
                      let deviceName =response.data.data.deviceName;
                      this.setDeviceHost(host);
                      this.setDeviceAuthToken(token);
                      this.setDeviceName(deviceName);
                      //   重定向到192.168
                      window.location.href = `http://${host}:8888/index.html`; 

                      this.connectionURL = '';
                      clearInterval(this.timer);
                      this.$emit('close', true);
                    }
                })
                .catch(error =>{
                    console.log(error);
                })
          }
        }
    }
  };
</script>
  
<style scoped>
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.qrcode{
  display: inline-block;
  img {
      width: 132px;
      height: 132px;
      background-color: #fff;
      padding: 6px;
      box-sizing: border-box;
  }
}

.btn {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 28px;
  font-size: 16px;
  cursor: pointer;
}

/* Gray */
.default {
  border-color: #e7e7e7;
  color: black;
}

.default:hover {
  background: #e7e7e7;
}
</style>