// 1、创建中文语言包对象
export default {
    menus: {
        home: '首页',
        download: '下载'
    },
    tab1: {
        transfer: '在线传输',
        download: '下载',
        sendtophone: '发送到手机',
        savetopc: '保存到电脑',
        history: '历史记录',
        connect: `连接中`,
        disconnect:'断开'
    },
    tab2: {
        photo:  '图片',
        video : '视频',
        music  : '音乐',
        application : '应用',
        document: '文档',
    },
    dialog: {
        match : '请使用手机APP扫描二维码进行匹配',
        
    },
    home: {
        name: '一键换机-克隆助手',
        introduce1:'面对面，一对一，零流量快速传输',
        introduce2:'一键解决您的换机问题！换机不再愁',
        btn_download: '立即下载',
        website_terms: '网站使用条款',
        user_privacya_greement: '用户隐私协议',
        presentation:  '产品介绍',
        cross_platform :'跨平台传输',
        cross_platform_1:'手机搬家支持iOS和安卓操作系统，',
        cross_platform_2:'您可以在不同的手机之间自由传输数据，无论是从iOS到安卓，还是从安卓到iOS，都能实现快速迁移。',
        multiple_data: '多种资料支持',
        multiple_data_1: '除了常见的照片、视频、音乐和联系人，手机搬家还支持传输日历、应用程序和各种文件，确保您可以完整迁移数据，不遗漏任何重要信息。',
        fast_and_stable_transmission: '快速稳定传输',
        fast_and_stable_transmission_1:'应用采用高效的数据传输技术，确保快速和稳定的传输过程。无论您的资料有多大，手机搬家都能高效地完成传输，节省您的时间。',
        safeguard:'安全保护',
        safeguard_1:'所有数据传输都采用加密技术，保证您的个人信息和隐私不会泄露。手机搬家致力于提供安全可靠的数据传输服务。',
        Android: '安卓',
        not_open: '（暂未开放）'
    }


}
